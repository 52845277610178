<div style="margin-bottom: 25px">
  <app-bss-textbox #testEmailInput [isEmail]="true" [labelText]="'Empfänger'" [(value)]="recipient"></app-bss-textbox>
</div>

<app-bss-button
  [text]="'Testen'"
  [disabled]="(testEmailInput.isValid | async) === false"
  (buttonClicked)="sendTestMail()"
  (disabledButtonClicked)="openInvalidEmailDialog()"
>
</app-bss-button>
