import { Component, Input } from '@angular/core';
import { Setting } from '../../entities';
import { DialogService, MailService } from '../../services';

@Component({
  selector: 'app-mailtest',
  templateUrl: './mailtest.component.html',
  styleUrls: ['./mailtest.component.scss'],
})
export class MailtestComponent {
  @Input() settings: Setting;
  recipient = '';
  openInvalidEmailDialog: () => Promise<void>;

  constructor(
    private dialogService: DialogService,
    private mailService: MailService,
  ) {
    this.openInvalidEmailDialog = this.mailService.openInvalidEmailDialog;
  }

  async sendTestMail(): Promise<void> {
    void this.dialogService.openLoadingDialog('E-Mail Test', '...Teste den Versand der E-Mail...'); // Kein Close Loadingdialog - der Mailservice macht die nächste Nachricht
    await this.mailService.sendTestMail(this.settings, this.recipient);
  }
}
