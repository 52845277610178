import { HWFile } from 'apps/handwerkPWA/src/app/entities';
import { OfflinePosition } from 'apps/handwerkPWA/src/app/interfaces';
import { PositionSettings } from '../entities';

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export class GlobalHelper {
  /**@description Macht das gleiche wie Object assign, außer, dass nur dinge zugewiesen werden, die vorhanden sind (im zweifel null, nicht undefined) */
  static assignIfPropertyExists(target: any, source: any): void {
    for (const property in source) {
      if (target[property] === undefined) {
        continue;
      }
      target[property] = source[property];
    }
  }

  /**@description Selbstgeschriebene null Or Undefined Function */
  static isNullOrUndefined(object: any): boolean {
    return object === null || object === undefined;
  }

  /**@description Selbstgeschriebene null Or Undefined Function */
  static isNullOrUndefinedOrEmptyString(stringValue: string): boolean {
    return stringValue === null || stringValue === undefined || stringValue === '';
  }

  /**@description Selbstgeschriebene null Or Undefined Function */
  static isNullOrUndefinedOrEmptyArray(object: any[]): boolean {
    return object === null || object === undefined || object.length === 0;
  }

  /**@description Überprüft, ob der angegebene wert vom typ boolean ist */
  static isBoolean(object: any): boolean {
    return typeof object === 'boolean';
  }

  /**@description Prüft ob das übergebene Object eine Instanz von Date ist */
  static isDate(object: any): boolean {
    return object instanceof Date;
  }

  /**@description Nimmt einen String einer Zahl oder eine Zahl und gibt einen Preis(Zahl, mit Komma und Zweinachkommastellen, gerundet auf die zweite Nachkommastelle) zurück */
  static convertToRoundedDisplayString(input: string | number, decimalPlaces: number = 2): string {
    const inputNumber = parseFloat(input.toString());
    const outputString = inputNumber.toFixed(decimalPlaces).replace('.', ',');
    return outputString;
  }

  /**@description Speichert etwas in den LocalStorage */
  static saveToLocalStorage(identifier: string, value: unknown): void {
    const stringifiedObject = JSON.stringify(value);
    localStorage.setItem(identifier, stringifiedObject);
  }

  /**
   * @description Liest etwas aus dem LocalStorage
   * @param className Optional um Konstruktor des Objekts zu durchlaufen - ist Klassenname des jeweiligen Objekts
   */
  static readFromLocalStorage<Type>(identifier: string, className?: new (data) => Type): Type {
    const stringifiedObject = localStorage.getItem(identifier);
    const rebuildObject = JSON.parse(stringifiedObject) as Type;
    if (className) return new className(rebuildObject);
    return rebuildObject;
  }

  /**@description Durch die Umwandlung in Strings kann es sein, dass eine Version bspw. "null" als String in die DB schrieb- diese Fälle werden erkannt */
  static isEmptyGUIData(objectToCheck: any): boolean {
    if (objectToCheck === null) return true;
    if (objectToCheck === undefined) return true;
    if (objectToCheck === 'null') return true;
    if (objectToCheck === '') return true;
    if (objectToCheck === '{}') return true;
    if (objectToCheck === '[]') return true;
    return false;
  }

  static removeControlCharacters(inputString: string): string {
    return inputString.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
  }

  /**
   * @description Gibt die nächste Nummer eines unsortierten number arrays
   * @param firstValue Erste Nummer dieses Typs, als Rückgabe falls keine höchste zahl gefunden wird (default 0) */
  static getNextNumber(numberArray: number[], firstValue = 0): number {
    if (!numberArray || numberArray.length === 0) return firstValue;
    const maxNumber = Math.max(...numberArray);
    return maxNumber + 1;
  }

  /**
   * @description Retrieves all unique elements between the start and end delimiter in the input string
   * @param inputString The string to search within
   * @param startDelimiter The start delimiter
   * @param endDelimiter The end delimiter
   * @returns An array of strings representing the elements between the delimiters
   * @example inputString = 'ab[h]gf[c]-öp?[e]fdfd[c]fdfd', startDelimiter = '[', endDelimiter = ']' returns ['h', 'c', 'e']
   */
  static getAllElementsBetween(inputString: string, startDelimiter: string, endDelimiter: string): string[] {
    // escape special characters in the delimiters
    startDelimiter = this.escapeRegExUserInput(startDelimiter);
    endDelimiter = this.escapeRegExUserInput(endDelimiter);
    // create a regex to find all elements between the start and end delimiter
    const regex = new RegExp(`${startDelimiter}(.*?)${endDelimiter}`, 'g');

    return [...new Set(inputString.match(regex))];
  }

  /**
   * @description Escapes special characters in a string to be used in a regular expression
   * @param input The string to escape
   * @returns The escaped string
   */
  static escapeRegExUserInput(input: string): string {
    return input.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  /**
   * @description Entfernt ein Element aus einem Array
   * @param comparePropertyName Name der Property, die zum Vergleich verwendet werden soll bei nicht trivialen arrays
   */
  static removeFromArray<Type>(inputArray: Type[], elementToRemove: Type, comparePropertyName?: string): Type[] {
    if (GlobalHelper.isNullOrUndefined(elementToRemove)) return inputArray;
    let index = inputArray.findIndex(entry => entry === elementToRemove);
    if (comparePropertyName)
      index = inputArray.findIndex(entry => entry[comparePropertyName] === elementToRemove[comparePropertyName]);
    if (index === -1) return inputArray;
    inputArray.splice(index, 1);
    return inputArray;
  }

  /**@default Parsed die Eingabe eines Distanzmessgeräts und gibt wie definiert 3 Nachkommastellen an */
  static parseDistanceMeterInput(distanceMeterInput: string): string {
    distanceMeterInput = distanceMeterInput.replace(',', '.');
    distanceMeterInput = distanceMeterInput.replace(/^[a-zA-Z]+/gi, '');
    const distanceMeterInputNumber = parseFloat(distanceMeterInput);
    if (isNaN(distanceMeterInputNumber)) return '';
    if (distanceMeterInputNumber < 1) return distanceMeterInputNumber.toPrecision(3).replace('.', ',');
    return distanceMeterInputNumber.toPrecision(4).replace('.', ',');
  }

  static calculateDataAmount(arrayData: any[]): string {
    const unknown = '(?)';
    if (GlobalHelper.isNullOrUndefined(arrayData)) return unknown;
    const length = arrayData.length;
    return `(${length})`;
  }

  /**@description Guckt ob die LieferantenSettings eines Lieferanten sich im Vergleich zum letzten mal geändert haben */
  static isChangeInSettingset(element: OfflinePosition, elements: OfflinePosition[]): boolean {
    const elementInArray = elements.find(findElement => findElement.UUID === element.UUID);
    const onlineAvailableEqual = element?.synchronize === elementInArray?.synchronize;
    const offlineAvailableEqual = element?.offlineAvailable === elementInArray?.offlineAvailable;
    return !(offlineAvailableEqual && onlineAvailableEqual);
  }

  static positionSettingChanged(
    oldPositionSettings: PositionSettings,
    newPositionSettings: PositionSettings,
    kind: 'Gewerk' | 'Lieferanten',
  ): boolean {
    const currentSettings =
      (kind === 'Gewerk'
        ? (newPositionSettings?.Gewerk as OfflinePosition[])
        : (newPositionSettings?.Lieferanten as OfflinePosition[])) || [];
    const oldSettings =
      (kind === 'Gewerk'
        ? (oldPositionSettings?.Gewerk as OfflinePosition[])
        : (oldPositionSettings?.Lieferanten as OfflinePosition[])) || [];
    const settingsChanged = currentSettings.some(setting => GlobalHelper.isChangeInSettingset(setting, oldSettings));
    if (settingsChanged || currentSettings?.length !== oldSettings?.length) return true;
    return false;
  }

  static splitFilenameAndExtension(fileNameWithExtension: string): { name: string; extension: string } {
    const dotPosition = fileNameWithExtension.lastIndexOf('.');
    const fileName = fileNameWithExtension.substring(0, dotPosition);
    const lowerCaseFileEnding =
      dotPosition !== -1 ? fileNameWithExtension.substring(dotPosition + 1)?.toLocaleLowerCase() : '';
    return { name: fileName, extension: lowerCaseFileEnding };
  }

  static base64To64Raw(base64string: string): string {
    const removeImage = base64string?.replace(/^data:image\/[a-z]+;base64,/, '');
    const removeText = removeImage?.replace(/^data:text\/[a-z]+;base64,/, '');
    return removeText;
  }

  static hWFileArrayGetUniques(fileArray: HWFile[]): HWFile[] {
    // weil im Handwerk die Bildnamen doppelt gespeichert werden und das dort auch auf Hinweis nicht geändert wird,
    // müssen wir uns quasi auf einen Bug anpassen... -.-
    const filesForAddress: HWFile[] = [];
    if (fileArray)
      for (const file of fileArray) {
        if (!filesForAddress.find(f => f.NameWithoutEnding === file.NameWithoutEnding)) filesForAddress.push(file);
      }
    return filesForAddress;
  }

  /**
   * @description CompareFunction für Sortierung. Sortierung der Werte aufsteigend
   * */
  static compareFunction(value1: string | number | Date, value2: string | number | Date, descending?: boolean): number {
    let compareValue: number;
    if (value1 > value2) {
      compareValue = 1;
    } else if (value1 < value2) {
      compareValue = -1;
    } else {
      compareValue = 0;
    }
    if (descending) compareValue = compareValue * -1;
    return compareValue;
  }

  static addIfNotIncluded<Type>(array: Type[], elementToAdd: Type[] | Type): Type[] {
    const values = Array.isArray(elementToAdd) ? elementToAdd : [elementToAdd];
    for (const value of values) {
      if (!array.includes(value)) array.push(value);
    }
    return array;
  }

  static convertToBase64(input: string): string {
    return btoa(input);
  }

  static convertFromBase64(input: string): string {
    return atob(input);
  }

  static isPartOfEnum<Type>(input: Type, inputEnum: object): boolean {
    return (Object.values(inputEnum) as Type[]).includes(input);
  }

  static objectEqualTo(obj1: object, obj2: object): boolean {
    // One of the object is null
    if (GlobalHelper.isNullOrUndefined(obj1) !== GlobalHelper.isNullOrUndefined(obj2)) return false;
    // Get the keys/properties of the first object
    const keys = Object.keys(obj1);

    for (const key of keys) {
      // Check if the property exists in both objects and if their values are not equal
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (
        obj1.hasOwnProperty(key) &&
        obj2.hasOwnProperty(key) &&
        JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])
      ) {
        return false; // Values are not the same, return false
      }
    }

    return true; // All values are the same, return true
  }
}
