/** Copy of the WebService secureSocketOptions */
export enum SecureSocketOptions {
  None,
  Auto,
  SslOnConnect,
  StartTls,
  StartTlsWhenAvailable,
}

export const secureSocketOptionDropdown = [
  {
    name: 'None',
    value: SecureSocketOptions.None,
  },
  {
    name: 'Auto',
    value: SecureSocketOptions.Auto,
  },
  {
    name: 'SslOnConnect',
    value: SecureSocketOptions.SslOnConnect,
  },
  {
    name: 'StartTls',
    value: SecureSocketOptions.StartTls,
  },
  {
    name: 'StartTlsWhenAvailable',
    value: SecureSocketOptions.StartTlsWhenAvailable,
  },
];
