import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
// eslint-disable-next-line import/no-unresolved
import { ButtonType } from 'devextreme/common';
// eslint-disable-next-line import/named
import { ContentReadyEvent } from 'devextreme/ui/button';

@Component({
  selector: 'app-bss-button',
  templateUrl: './bss-button.component.html',
  styleUrls: ['./bss-button.component.scss'],
})
export class BssButtonComponent implements OnChanges {
  @Input() icon: string;
  @Input() text: string;
  @Input() type: ButtonType = 'default';
  @Input() width: number | string;
  @Input() disabled = false;

  @Output() buttonClicked = new EventEmitter<void>();
  @Output() disabledButtonClicked = new EventEmitter<void>();
  ownActionElement: Element;

  onClick(): void {
    if (this.disabled) this.disabledButtonClicked.emit();
    else this.buttonClicked.emit();
  }

  ngOnChanges(): void {
    this.setCss();
  }

  setCss(): void {
    if (!this.ownActionElement) return;
    // icon buttons should not have a background color
    if (this.icon) return;

    const enabledClass = 'primary-button';
    const disabledClass = 'primary-button-disabled';
    if (this.disabled) {
      this.ownActionElement.classList.remove(enabledClass);
      this.ownActionElement.classList.add(disabledClass);
      return;
    }
    if (!this.disabled) {
      this.ownActionElement.classList.add(enabledClass);
      this.ownActionElement.classList.remove(disabledClass);
      return;
    }
  }

  onContentReady(event: ContentReadyEvent): void {
    this.ownActionElement = event.element;

    this.setCss();
  }
}
