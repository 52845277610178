import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  loggedIn = new BehaviorSubject<boolean>(true);
  private blockInputs = new BehaviorSubject<boolean>(false);

  public async clickButton<T>(func: (...args: any[]) => Promise<T>): Promise<T> {
    if (this.blockInputs.value) return undefined;
    this.blockInputs.next(true);
    try {
      return await func();
    } finally {
      this.blockInputs.next(false);
    }
  }
}
