import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxAccordionModule,
  DxBoxModule,
  DxButtonModule,
  DxCalendarModule,
  DxCheckBoxModule,
  DxColorBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxListModule,
  DxLoadPanelModule,
  DxMultiViewModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTemplateModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/** Components */
import {
  DataIsLoadingComponent,
  DocumentSettingsComponent,
  PdfSettingEditorComponent,
  RightCoreComponent,
  SideNavigationComponent,
} from './components';

import {
  BssButtonComponent,
  BssNumberboxComponent,
  BssSelectBoxComponent,
  BssSwitchboxComponent,
  BssTextareaComponent,
  BssTextboxComponent,
  MailtestComponent,
} from 'libs/shared/src/lib/components';

/** Pages */
import {
  EmailSettingsComponent,
  ErrorComponent,
  ItemPositionChoiceComponent,
  RightsEditComponent,
  RolesEditComponent,
  SettingComponent,
  TextModulesComponent,
} from './pages';

import { ScreenService } from 'libs/shared/src/lib/services/screen.service';

@NgModule({
  declarations: [
    AppComponent,
    SettingComponent,
    RightsEditComponent,
    RolesEditComponent,
    PdfSettingEditorComponent,
    ItemPositionChoiceComponent,
    ErrorComponent,
    MailtestComponent,
    RightCoreComponent,
    DocumentSettingsComponent,
    SideNavigationComponent,
    BssSelectBoxComponent,
    BssSwitchboxComponent,
    BssTextboxComponent,
    BssNumberboxComponent,
    EmailSettingsComponent,
    TextModulesComponent,
    BssTextareaComponent,
    BssButtonComponent,
    DataIsLoadingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DxSelectBoxModule,
    DxListModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    DxFormModule,
    DxTextAreaModule,
    DxDateBoxModule,
    DxButtonModule,
    DxTextBoxModule,
    DxMultiViewModule,
    DxCalendarModule,
    DxTemplateModule,
    DxLoadPanelModule,
    DxPopupModule,
    DxSwitchModule,
    DxDataGridModule,
    DxBoxModule,
    DxRadioGroupModule,
    FormsModule,
    DxTooltipModule,
    DxColorBoxModule,
    HttpClientModule,
    DxTabPanelModule,
    DxAccordionModule,
    DxValidatorModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'de' }, ScreenService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
