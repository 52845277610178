import { Component, OnInit } from '@angular/core';
import { key } from '@handwerk-pwa/shared';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import deMessages from 'apps/handwerkPWA/src/assets/de.json';
import config from 'devextreme/core/config';
import { loadMessages, locale } from 'devextreme/localization';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { BrowserService } from 'libs/shared/src/lib/services/browser.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

config({
  licenseKey: key,
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isErrorPage = false;

  constructor(
    private routingService: RoutingService,
    private globalSettingsService: HWGlobalSettingService,
    private browserService: BrowserService,
  ) {
    loadMessages(deMessages);
    locale(navigator.language);
  }

  ngOnInit(): void {
    const isInternetExplorer = this.browserService.checkForInternetExplorer();
    if (isInternetExplorer) return;

    this.routingService.currentRoute.subscribe(currentRoute => {
      this.checkErrorPage(currentRoute);
    });
    // saves it in two different localStorage names because it has different properties that would be overwritten if you use both at the same time
    if (
      window.location.href.includes('/Rechte') ||
      window.location.href.includes('/Rollenverwaltung') ||
      window.location.href.includes('/Positionswahl') ||
      window.location.href.includes('/Gewerkewahl')
    )
      GlobalHelper.saveToLocalStorage('productName', 'frontendGUIRechte');
    else GlobalHelper.saveToLocalStorage('productName', 'frontendGUI');
    this.globalSettingsService.globalIgnoreChanges = true;
  }

  private checkErrorPage(currentRoute: string): void {
    this.isErrorPage = ['/', '/Fehler', '/Fehler/no-login'].includes(currentRoute) ? true : false;
  }
}
