import { SecureSocketOptions } from './SecureSocketOptions';

export class EmailConnectionInfo {
  smtpUser: string;
  smtpPassword: string;
  smtpServer: string;
  smtpPort: number;
  displayName: string;
  smtpSecurity: SecureSocketOptions = SecureSocketOptions.Auto;
  email: string;
}
