<div class="dx-field">
  <div *ngIf="labelText" class="dx-field-label">{{ labelText }}</div>
  <div class="dx-field-value" [ngClass]="{ 'input-with-button': buttonIcon && buttonEnabled }">
    <dx-text-box
      [valueChangeEvent]="'input'"
      (onFocusOut)="focusWentOut(value)"
      [(value)]="value"
      [mode]="mode"
      [maxLength]="maxLength"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [placeholder]="placeholder"
      (onValueChanged)="valueChanged(value)"
      [rtlEnabled]="rtlEnabled"
      [width]="width"
    >
      <dx-validator *ngIf="isEmail" (onValidated)="onValidated($event)">
        <dxi-validation-rule type="email" message="E-Mail-Adresse ist ungültig."> </dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
    <dx-button *ngIf="buttonIcon && buttonEnabled" [icon]="buttonIcon" (onClick)="buttonClicked()"></dx-button>
  </div>
</div>
