import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  EmailConnectionInfo,
  SecureSocketOptions,
  Setting,
  Textvorlage,
  UserInfo,
  secureSocketOptionDropdown,
} from '../../../../../../../libs/shared/src/lib/entities';
import { GlobalHelper } from '../../../../../../../libs/shared/src/lib/helper';
import { DialogService, MailService, RightsService } from '../../../../../../../libs/shared/src/lib/services';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss'],
})
export class EmailSettingsComponent implements OnInit {
  setting: Setting;
  userInfo: UserInfo;
  emailConnectionInfo: EmailConnectionInfo = new EmailConnectionInfo();
  currentEmailtext: Textvorlage;
  rendered = false;
  secureSocketOptionDropdown = secureSocketOptionDropdown;
  selectedSocketOption: { name: string; value: SecureSocketOptions };
  openInvalidEmailDialog: () => Promise<void>;

  constructor(
    private titleService: Title,
    private rightsService: RightsService,
    private dialogService: DialogService,
    private mailService: MailService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.openInvalidEmailDialog = this.mailService.openInvalidEmailDialog;
    this.titleService.setTitle('E-Mail Einstellungen');
    this.userInfo = GlobalHelper.readFromLocalStorage('guiUserInfo');
    this.setting = await this.rightsService.getSettingFromWebService(this.userInfo, true);
    if (this.setting?.emailConnectionInfo) this.emailConnectionInfo = this.setting.emailConnectionInfo;
    if (this.setting?.emailConnectionInfo?.smtpPassword)
      this.setting.emailConnectionInfo.smtpPassword = window.atob(this.setting.emailConnectionInfo.smtpPassword);

    if (this.setting.emailTextvorlage) {
      this.currentEmailtext = this.setting.textVorlagen.find(
        vorlage => vorlage.Nummer === this.setting.emailTextvorlage.Nummer,
      );
    }
    this.selectedSocketOption = this.secureSocketOptionDropdown.find(
      dropDownOptions => dropDownOptions.value === this.emailConnectionInfo.smtpSecurity,
    );
    this.rendered = true;
  }

  async save(): Promise<void> {
    if (await this.mailService.validateEmailConnection(this.emailConnectionInfo)) {
      this.setting.emailConnectionInfo = { ...this.emailConnectionInfo };
      await this.rightsService.sendSettingToWebService(this.setting, this.userInfo);
      this.dialogService.openInformDialog('Erfolgreich', 'SMTP-Einstellungen wurden erfolgreich gespeichert', 'Ok');
    }
  }

  onEmailtextChange(textvorlage: Textvorlage): void {
    this.setting.emailTextvorlage = textvorlage;
  }
}
