<div *ngIf="rendered">
  <h2 class="page-title">E-Mail Einstellungen</h2>

  <div class="dx-fieldset">
    <div class="dx-fieldset-header">SMTP-Einstellungen</div>

    <div class="fieldbox">
      <app-bss-textbox
        #userEmailInput
        [labelText]="'Email'"
        [(value)]="emailConnectionInfo.email"
        [maxLength]="50"
        [isEmail]="true"
      >
      </app-bss-textbox>
      <app-bss-textbox [labelText]="'Anzeigename'" [(value)]="emailConnectionInfo.displayName"></app-bss-textbox>
      <app-bss-textbox [labelText]="'SMTP-Benutzername'" [(value)]="emailConnectionInfo.smtpUser"></app-bss-textbox>
      <app-bss-textbox
        [labelText]="'SMTP-Passwort'"
        [(value)]="emailConnectionInfo.smtpPassword"
        [mode]="'password'"
      ></app-bss-textbox>
      <app-bss-textbox [labelText]="'SMTP-Server'" [(value)]="emailConnectionInfo.smtpServer"></app-bss-textbox>
      <app-bss-numberbox [labelText]="'SMTP-Port'" [(value)]="emailConnectionInfo.smtpPort"></app-bss-numberbox>
      <app-bss-select-box
        [labelText]="'Sicherheit'"
        [items]="secureSocketOptionDropdown"
        [displayExpr]="'name'"
        [value]="selectedSocketOption"
        (selectedValue)="emailConnectionInfo.smtpSecurity = $event.value"
      ></app-bss-select-box>
    </div>

    <app-bss-select-box
      [showClearButton]="true"
      [labelText]="'E-Mail Textvorlage'"
      [placeholder]="'auswählen'"
      [items]="setting.textVorlagen"
      [value]="currentEmailtext"
      [displayExpr]="'SuchText'"
      (selectedValue)="onEmailtextChange($event)"
    ></app-bss-select-box>
  </div>

  <app-bss-button
    [text]="'Speichern'"
    [disabled]="(userEmailInput.isValid | async) === false"
    (buttonClicked)="save()"
    (disabledButtonClicked)="openInvalidEmailDialog()"
  >
  </app-bss-button>

  <div class="dx-fieldset" style="padding-top: 20px">
    <div class="dx-fieldset-header">E-Mail Test</div>

    <app-mailtest [settings]="setting"></app-mailtest>
  </div>
</div>
